<template>
  <div class="home">
    <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div>
    <div class="contant">
      <template v-for="(item, index) in fields" :key="index">
        <userinfo v-if="item.type == 'userinfo'" />
        <titletabs v-if="item.type == 'title_tab'" :field="item" />
        <datashow v-if="item.type == 'info'" :field="item" style="margin-top: 50px;" />
        <CashOut v-if="item.type == 'cash'" :field="item" />
      </template>
      <div class="subcontant">
        <div class="banner">
          <van-swipe indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="toRoutClick(item)">
              <img :src="item.img" alt="">
            </van-swipe-item>
          </van-swipe>
        </div>
        <template v-for="(item, index) in fields" :key="index">
          <div class="listchart" v-if="item.type == 'navs'">
            <div class="title">
              <span>{{ item.text }}</span>
            </div>
            <menubar :field="item" />
          </div>
          <div class="listchart" v-if="item.type == 'map'">
            <div class="title">
              <span>{{ item.text }}</span> <button type="button" class="more" v-if="item.more_url"
                @click="toPath(item.more_url)">更多</button>
            </div>
            <echart :field="item" :setId="index" />
          </div>
          <div class="listchart" style="max-height: 510px" v-if="item.type == 'table'">
            <div class="title">
              <span>{{ item.text }}</span> <button type="button" class="more" v-if="item.more_url"
                @click="toPath(item.more_url)">更多</button>
            </div>
            <tableform :field="item" />
          </div>
          <div class="listchart tabs" v-if="item.type == 'table_tab'">
            <div class="title">
              <span>{{ item.text }}</span> <button type="button" class="more" v-if="item.more_url"
                @click="toPath(item.more_url)">更多</button>
            </div>
            <!-- <div class="showdata">
              <datatabs :tabsData="tabslabel" :showsingle="true" :showmultiple="false" :tclass="item.color_class?item.color_class:'gray'" />
              <datacont />
            </div> -->
            <shoplist :field="item" title="" />
          </div>

        </template>

      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated } from "vue";
import mitts from "@/bus";
import { useRoute, useRouter } from "vue-router";
import menubar from "@/components/menu";
import echart from "@/components/echart";
import tableform from "@/components/table";
import datashow from "@/components/datashow";
import shoplist from "@/components/shoplist";
import titletabs from "@/components/titletabs";
import CashOut from "@/components/CashOut";
import userinfo from "@/components/userinfo";
import { Toast } from "vant";
import API from "@/axios";
const route = useRoute();
const router = useRouter();
const fields = ref({});
let in_page = '';

function toPath(url) {
  mitts.emit("showLoading");
  router.push({
    name: 'event',
    query: { url: encodeURIComponent(url) }
  });
}

function getData() {
  API({
    url: "/web/" + in_page,
    method: "get",
  }).then((res) => {
    fields.value = res.data.data.field;
    mitts.emit('navs', res.data.data.navs);
    if (in_page == "mine") {
      mitts.emit("navindex", "mine")
    } else if (in_page == "myHome") {
      mitts.emit("navindex", "home")
    }
    setTimeout(() => {
      mitts.emit("closeLoading");
    }, 500);
  });
}

const bannerList = ref([])

function toRoutClick(val) {
  console.log(val)
  router.push({
    path: val.path,
    query: val.path_params
  });
}

function entryConfig() {
  API({
    url: "/web/activity/entryConfig",
    method: "post",
    data: {
      search: {
        position: 2,
      }
    },
  }).then((res) => {
    if (res.data.status == 200) {
      bannerList.value = res.data.data[0]
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(() => {
  in_page = route.params.pt;
  getData();
  entryConfig()
  mitts.emit("toptitle", false);
});
onUpdated(() => {
  if (in_page && in_page != route.params.pt) {
    in_page = route.params.pt;
    fields.value = {};
    getData();
  }
});
</script>

<style lang="less" scoped>
.home {
  position: relative;
  height: 100%;
  width: 100%;

  .topimg {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }
}

.contant {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
}

.subcontant {
  padding-top: 20px;
  min-height: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.listchart {
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: auto;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-size: @title-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @title-size;
    }

    .more {
      background: @theme-color;
      font-size: @fontmini-size;
      font-family: "Bold";
      color: @font-white-color;
      line-height: @fontmini-size;
      width: 120px;
      height: 50px;
      border-radius: 25px;
    }
  }
}

.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;

  .item {
    margin-right: 50px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;

    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  .on {
    font-weight: bolder;

    span {
      display: block;
    }
  }
}

.tabs {
  height: auto;
}

.banner {
  padding: 0 30px;
  margin-bottom: 30px;

  img {
    width: 100%;
    display: block;
  }
}
</style>
