<template>
  <div class="usrinfo">
    <div class="avatar"  @click="upAvatar">
<!--      <img :src="info.head_img" alt="" />-->

      <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="info.head_img"
      />
    </div>
    <div class="username">
      <div class="name">{{info.nickname}}</div>
      <div class="userid">角色：{{info.roles}}</div>
      <div class="bindwx">绑定微信：{{info.wx_nickname}}</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from 'vue-router';
import API from "@/axios";
import mitts from "@/bus";
// const img = require("@/assets/img/avatar.png");
const router = useRouter();
const info = ref({});
function getData() {
  API({
    url: '/web/info',
    method: "post",
  }).then((res) => {
    info.value = res.data.data;
  });
}

function upAvatar(){
  mitts.emit("showLoading");
  router.push({
    name: 'event',
    query: {url:encodeURIComponent('/web/upAvatar')}
  });
}
onMounted(()=>{
  getData();
});
</script>

<style lang="less" scoped>
.usrinfo {
  padding: 25px 25px 0 25px;
  display: flex;
  align-items: center;
  margin-bottom: 43px;
  .avatar {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
    margin-right: 30px;
    img {
      width: 100%;
      display: block;
    }
  }
  .username {
    flex: 1;
    .name {
      font-size: @title-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-white-color;
      line-height: @title-size;
      margin-bottom: 20px;
    }
    .userid {
      font-size: @font-size;
      font-family: "Regular";
      color: @font-white-color;
      line-height: @font-size;
    }
    .bindwx {
      margin-top: 20px;
      font-size: @font-size;
      font-family: "Regular";
      color: @font-white-color;
      line-height: @font-size;
    }
  }
}
</style>
