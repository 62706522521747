<template>
  <div class="menu">
    <div
      class="item"
    v-for="(item, index) in realMenus"
    :key="index"
      @click="toRout(item)"
    >
      <div class="icon"><img :src="item.img" alt="" /></div>
      <div class="text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps,toRefs,onMounted } from "vue";
import { useRouter } from "vue-router";
import mitts from "@/bus";
import API from "@/axios";
import {Toast} from "vant";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  menus: {
    type: Array,
    required: false,
  }
});
const realMenus = ref({});
const { field, menus } = toRefs(props);
const router = useRouter();

function toRout(item) {
  mitts.emit("showLoading");
  if(item.event == 'logout'){
    API({
      url: item.url,
      method: "post",
    }).then(() => {
      window.localStorage.removeItem("timestamp");
      setTimeout(function(){
        router.push({
          path: '/saas/login'
        });
      }, 500);
    });
    return false;
  }else if(item.event == 'bindwx'){
    API({
      url: item.url,
      method: "post",
    }).then((res) => {
      if(res.data.status == 200){
        window.location.href = res.data.data.url;
      }else {
        Toast.fail({
          message: res.data.msg
        });
      }
    });
    return false;
  }else if(item.event == 'as_blank'){
    // 跳转到as的链接
    window.location.replace(item.url);
  }

  if(item.url){
    router.push({
      path: item.url,
      query: item.query
    });
  }else{
    if (name !== "") {
      router.push({
        name: item.rout,
        query: {
          pagename: item.pagename,
        },
      });
    } else {
      router.push({
        name: item.rout,
      });
    }

  }
}

// function toUrl(url){
//     router.push({
//       path: url
//     });
// }
onMounted(() => {
  if(field.value){
    realMenus.value = field.value.field;
  }else{
    realMenus.value = menus.value;
  }
});
</script>

<style lang="less" scoped>
.menu {
  display: flex;
  flex-wrap: wrap;
  //padding: 0 20px;
  .item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-bottom: 50px;
    .icon {
      width: 90px;
      height: 90px;
      margin-bottom: 24px;
      img {
        display: block;
        width: 100%;
      }
    }
    .text {
      font-size: @font-size;
      font-family: "Bold";
      color: @font-color;
      line-height: @font-size;
    }
  }
}
</style>
