<template>
  <div class="showdata">
    <div class="datashow">
      <div class="item" v-for="(item, index) in data" :key="index">
        <template v-if="item.text">
          <div class="text">{{ item.text }}</div>
          <div class="math">{{ item.val }}</div>
        </template>
        <template v-else>
          <div class="text">{{ index }}</div>
          <div class="math">{{ item }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps,ref } from "vue";
import API from "@/axios";

const props = defineProps({
  field: {
    type: Object,
    required: false,
  }
});
const { field } = toRefs(props);

const data = ref([]);

onMounted(() => {
  if(field.value){
    getData();
  }
});


function getData() {
  API({
    url: field.value.url,
    method: "post",
  }).then((res) => {
    data.value = res.data.data;
  });
}
</script>

<style lang="less" scoped>
.showdata {
  padding: 0 24px;
}
.datashow {
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-width: 25%;
    margin-bottom: 20px;
    .text {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .math {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
    }
  }
}
</style>
